'use strict'

angular
  .module 'mundoUtils'
  .config ($stateProvider) ->
    $stateProvider
      .state 'mundoUtils',
        url: '/mundo-utils'
        templateUrl: 'mundo-utils/views/mundo-utils.tpl.html'
        controller: 'MundoUtilsCtrl'
        controllerAs: 'mundoUtils'
